import Vue from 'vue';
import Router from 'vue-router';
const RouteView = {
  name: 'RouteView',
  render: h => h('router-view')
}
Vue.use(Router);

// 解决重复路由跳转报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: "index",
    component:() => import('@/layout/index.vue'),
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: "home",
        component:() => import('@/pages/home/index.vue'),
        meta: {
          title: '首页',
          icon: 'home',
        },
      },
      {
        path: '/join',
        name: "join",
        component:() => import('@/pages/join/index.vue'),
        meta: {
          title: '加盟连锁',
          icon: 'join',
        },
      },
      {
        path: '/floralArt',
        name: "floralArt",
        component:() => import('@/pages/floralArt/index.vue'),
        meta: {
          title: '花艺培训',
          icon: 'floralArt',
        },
      },
      {
        path: '/operate',
        name: "operate",
        component:() => import('@/pages/operate/index.vue'),
        meta: {
          title: '关于我们',
          icon: 'operate',
        }
      },
      {
        path: '/about',
        name: "about",
        component:() => import('@/pages/about/index.vue'),
        meta: {
          title: '关于我们',
          icon: 'about',
        }
      },
    ]
  }
]

const  scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition && to.meta.keepAlive) {
    return savedPosition;
  }
  return { x: 0, y:0 };
}

const router = new Router({
  routes: routes,
  scrollBehavior
})

export default router