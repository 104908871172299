<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {}
}
</script>

<style lang="less">
html,body {
  // height: 100%;
}
body {
  background: #FFFFFF;
  color: #071422;
}
* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: #2B2E2C;
}

.el-popper.book-popover {
  width: 241px !important;
  padding: 0;
  border: none;
  box-shadow: none;
  background: none;
  .popper__arrow {
    display: none;
  }
}
</style>
